<template>
    <div>
        <v-container>
            <v-overlay :value="isLoading">
                <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
            </v-overlay>
            <!-- ============================================= -->
            <h2>{{ dbDevice.name }}</h2>
            <v-divider class="mb-3"></v-divider>
            <!-- ============================================= -->
            <v-row>
                <v-col cols="5">
                    <v-menu v-model="dispCalender" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense v-model="targetDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="targetDate" @input="dispCalender = false" locale="ja" :max="maxDate" @change="chartReload()"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <v-select outlined dense v-model="daySpanValue" :items="daySpanOptions" @change="chartReload()"></v-select>
                </v-col>
            </v-row>
            <!-- ################# 給餌量 ##################################### -->
            <div v-show="true">
                <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
                    <v-card-title class="blue darken-2 pa-2">
                        <h4 class="white--text">給餌量({{ dbDevice.feed_unit }})</h4>
                        <v-spacer></v-spacer>
                        <div v-show="dbUser.permit.includes('feed')">
                            <v-btn class="ma-1" :to="{ name: 'FeededList', params: { device_id: device_id } }" color="success"><v-icon>mdi-beaker-plus</v-icon>給餌量/尾数</v-btn>
                        </div>
                    </v-card-title>
                    <div class="white chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                        <comp-chart-bar :chartDataChild="chartDataSetFeed" :yRange="chartRange.feed" style="height: 60vh" />
                    </div>
                </v-card>
            </div>
            <div class="ma-5"></div>
            <!-- ################# 収容尾数 ##################################### -->
            <div>
                <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
                    <v-card-title class="blue darken-2 pa-2">
                        <h4 class="white--text">収容尾数</h4>
                        <v-spacer></v-spacer>
                        <div v-show="dbUser.permit.includes('feed')">
                            <v-btn class="ma-1" :to="{ name: 'FeededList', params: { device_id: device_id } }" color="success"><v-icon>mdi-beaker-plus</v-icon>給餌量/尾数</v-btn>
                        </div>
                    </v-card-title>
                    <div class="white chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                        <comp-chart-line :chartDataChild="chartDataSetFishCount" :yRange="chartRange.fish" style="height: 60vh" />
                    </div>
                </v-card>
            </div>
            <div class="ma-5"></div>
            <!-- ################# 水温 ##################################### -->
            <div v-show="isExistSensor('CT') || isExistSensor('DO')">
                <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
                    <v-card-title class="blue darken-2 pa-2">
                        <h4 class="white--text">水温 (℃)</h4>
                    </v-card-title>
                    <div class="white chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                        <comp-chart-line :chartDataChild="chartDataSetWater" :yRange="chartRange.water" style="height: 60vh" />
                    </div>
                </v-card>
            </div>
            <div class="ma-5"></div>
            <!-- ################## 塩分 #################################### -->
            <div v-show="isExistSensor('CT')">
                <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
                    <v-card-title class="blue darken-2 pa-2">
                        <h4 class="white--text">塩分</h4>
                    </v-card-title>
                    <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                        <comp-chart-line :chartDataChild="chartDataSetSalinity" :yRange="chartRange.salinity" style="height: 60vh" />
                    </div>
                </v-card>
            </div>
            <div class="ma-5"></div>
            <!-- ################## DO ################################### -->
            <div v-show="isExistSensor('DO')">
                <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
                    <v-card-title class="blue darken-2 pa-2">
                        <h4 class="white--text">DO (mg/L)</h4>
                    </v-card-title>
                    <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                        <comp-chart-line :chartDataChild="chartDataSetOxygen" :yRange="chartRange.oxygen" style="height: 60vh" />
                    </div>
                </v-card>
            </div>
            <div class="ma-5"></div>
            <!-- ################## pH #################################### -->
            <div v-show="isExistSensor('PH')">
                <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
                    <v-card-title class="blue darken-2 pa-2">
                        <h4 class="white--text">pH</h4>
                    </v-card-title>
                    <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                        <comp-chart-line :chartDataChild="chartDataSetPH" :yRange="chartRange.ph" style="height: 60vh" />
                    </div>
                </v-card>
            </div>
            <div class="ma-5"></div>
            <!-- ###################################################### -->
        </v-container>
    </div>
</template>

<style></style>

<script>
import CompChartLine from "@/components/CompChartLine";
import CompChartBar from "@/components/CompChartBar";

export default {
    name: "DeviceChart",
    components: {
        // CompChartWater,
        // CompChartSalinity,
        // CompChartOxygen,
        CompChartLine,
        CompChartBar,
    },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: undefined,
            dbUser: { id: "", name: "", permit: [] },
            dbDevice: {},
            dbSensorLogs: undefined,
            dbFeededLogs: undefined,
            dbData: {
                xyWaterSalinity: [],
                xyWaterOxygen: [],
                xySalinity: [],
                xyOxygen: [],
                xyPH: [],
                xyFeed: [],
                xyFishCount: [],
            },
            //---------------------------
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            //---------------------------
            dispCalender: false,
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            //---------------------------
            chartColor: {
                water_salinity: "#7fd1ea",
                water_oxygen: "#000d88",
                salinity: "#5d5d5d",
                oxygen: "#a3c4ff",
                ph: "#008b8b",
                feed: "#8b4513",
                fish_count: "#2e8b57",
            },
            chartLabel: {
                water_salinity: "水温",
                water_oxygen: "水温",
                salinity: "塩分",
                oxygen: "DO",
                ph: "pH",
                feed: "給餌量",
                fish_count: "収容尾数",
            },
            chartRange: {
                fish: { min: 0, max: 0 },
                feed: { min: 0, max: 0 },
                water: { min: 0, max: 0 },
                salinity: { min: 0, max: 0 },
                oxygen: { min: 0, max: 0 },
                ph: { min: 0, max: 0 },
            },
            daySpanValue: 2,
            daySpanOptions: [
                { text: "1日間", value: 1 },
                { text: "2日間", value: 2 },
                { text: "7日間", value: 7 },
                { text: "14日間", value: 14 },
                { text: "30日間", value: 30 },
            ],
            //---------------------------
            chartDataSetWater: { datasets: [] },
            chartDataSetSalinity: { datasets: [] },
            chartDataSetOxygen: { datasets: [] },
            chartDataSetPH: { datasets: [] },
            chartDataSetFeed: { datasets: [] },
            chartDataSetFishCount: { datasets: [] },
            //---------------------------
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------------------
        this.device_id = this.$route.params.device_id;
        if (this.$route.query.targetDate) this.targetDate = this.$route.query.targetDate;
        //---------------------------------------------
        //this.modalReset();
        this.isLoading = true;

        const promiseUser = this.axiosGetUsers();
        await promiseUser;

        this.localRangeUpdate();
        await this.getDevices();
        await this.chartReload();
        this.isLoading = false;
        window.scrollTo(0, 0);
    },
    //========================================================
    computed: {},
    //========================================================
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
    },
    //========================================================
    methods: {
        //====================================================
        handleResize: function () {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        chartHeight() {
            let h = Math.floor(this.windowHeight / 3);
            if (h < 280) h = 280;
            if (this.windowWidth < 500) h = 700; //スマホ縦表示は700px
            //return h + "px";
            return "auto";
        },
        //====================================================
        async chartReload() {
            if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
            this.isLoading = true;
            await new Promise((r) => setTimeout(r, 500));
            const sdays = (this.daySpanValue - 1) * -1;
            const sdate = this.$moment(this.targetDate).add(sdays, "days").format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate).add(1, "days").format("YYYY-MM-DD");
            this.dbSensorLogs = undefined;
            await this.getSensorData(sdate, edate);
            await this.getFeededData(sdate, edate);
            await this.registXYdata(sdate, edate);
            await this.registSeaChartData();
            this.isLoading = false;
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                params: { _fields: "id,name,permit" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                    let permit = this.dbUser["permit"];
                    this.dbUser["permit"] = permit.split(",");
                }
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id,
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                if (response.data.json[0]) this.dbDevice = response.data.json[0];
            });
        },
        //====================================================
        async localRangeUpdate() {
            if (this.$localStorage.get("chart.range.json") != undefined) {
                let localRange = JSON.parse(this.$localStorage.get("chart.range.json"));
                if (Number(localRange["fish"]["min"])) this.chartRange.fish.min = Number(localRange["fish"]["min"]);
                if (Number(localRange["fish"]["max"])) this.chartRange.fish.max = Number(localRange["fish"]["max"]);
                if (Number(localRange["feed"]["min"])) this.chartRange.feed.min = Number(localRange["feed"]["min"]);
                if (Number(localRange["feed"]["max"])) this.chartRange.feed.max = Number(localRange["feed"]["max"]);
                if (Number(localRange["water"]["min"])) this.chartRange.water.min = Number(localRange["water"]["min"]);
                if (Number(localRange["water"]["max"])) this.chartRange.water.max = Number(localRange["water"]["max"]);
                if (Number(localRange["salinity"]["min"])) this.chartRange.salinity.min = Number(localRange["salinity"]["min"]);
                if (Number(localRange["salinity"]["max"])) this.chartRange.salinity.max = Number(localRange["salinity"]["max"]);
                if (Number(localRange["oxygen"]["min"])) this.chartRange.oxygen.min = Number(localRange["oxygen"]["min"]);
                if (Number(localRange["oxygen"]["max"])) this.chartRange.oxygen.max = Number(localRange["oxygen"]["max"]);
                if (Number(localRange["ph"]["min"])) this.chartRange.ph.min = Number(localRange["ph"]["min"]);
                if (Number(localRange["ph"]["max"])) this.chartRange.ph.max = Number(localRange["ph"]["max"]);
            }
        },
        //====================================================
        async getSensorData(sdate, edate) {
            //--------------------------------
            let requestURL = "/web/api/sensor_logs";
            const params = {
                device_id: this.device_id,
                "measured_at[EQGREAT]": sdate,
                "measured_at[SMALL]": edate,
                _fields: "sensor_key,sensor_val,measured_at",
                // "_order[id]": "asc",
            };
            await this.axios({
                method: "GET",
                url: requestURL,
                params: params,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSensorLogs = response.data.json;
            });
        },
        //====================================================
        async getFeededData(sdate, edate) {
            //--------------------------------
            let requestURL = "/web/api/feeded_logs";
            const params = {
                device_id: this.device_id,
                "measured_at[EQGREAT]": sdate,
                "measured_at[SMALL]": edate,
                _fields: "measured_at,feeded_weight,fish_count",
                // "_order[id]": "asc",
            };
            await this.axios({
                method: "GET",
                url: requestURL,
                params: params,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbFeededLogs = response.data.json;
            });
        },
        //====================================================
        async registXYdata(sdate, edate) {
            //self.console.log(sdate, edate);
            //----------------------------------------------------------------
            // 初期化
            const defaultPoint = [
                { x: sdate, y: null },
                { x: edate, y: null },
            ];
            this.dbData.xyWaterSalinity = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterOxygen = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xySalinity = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyOxygen = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyPH = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyFeed = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyFishCount = JSON.parse(JSON.stringify(defaultPoint));
            //----------------------------------------------------------------
            // 当日(指定日)
            for (let i in this.dbSensorLogs) {
                let x = this.dbSensorLogs[i].measured_at;
                x = this.$moment(x).format("YYYY-MM-DD HH:mm");
                let y = this.dbSensorLogs[i].sensor_val;
                let s = this.dbSensorLogs[i].sensor_key;
                if (s == "salinity_temp") this.dbData.xyWaterSalinity.push({ x: x, y: Number(y) });
                if (s == "oxygen_temp") this.dbData.xyWaterOxygen.push({ x: x, y: Number(y) });
                if (s == "salinity") this.dbData.xySalinity.push({ x: x, y: Number(y) });
                if (s == "oxygen") this.dbData.xyOxygen.push({ x: x, y: Number(y) });
                if (s == "ph") this.dbData.xyPH.push({ x: x, y: Number(y) });
            }
            //---------------------------------
            for (let i in this.dbFeededLogs) {
                let x = this.dbFeededLogs[i].measured_at;
                x = this.$moment(x).format("YYYY-MM-DD HH:mm");
                let y = this.dbFeededLogs[i].fish_count;
                if (!Number.isNaN(parseInt(y))) {
                    this.dbData.xyFishCount.push({ x: x, y: Number(y) });
                }
            }
            //---------------------------------
            for (let i in this.dbFeededLogs) {
                let x = this.dbFeededLogs[i].measured_at;
                x = this.$moment(x).format("YYYY-MM-DD HH:mm");
                let y = this.dbFeededLogs[i].feeded_weight;
                if (!Number.isNaN(parseInt(y))) {
                    this.dbData.xyFeed.push({ x: x, y: Number(y) });
                }
            }
            this.dbData.xyFeed = convBarData(this, this.dbData.xyFeed);
            //---------------------------------
            function convBarData(pThis, argData) {
                let stepSec = 60 * 60 * 1; // 累計のまとめ時間(1時間)
                //let stepSec = 60 * 10; // 累計のまとめ時間(10分間)
                const utcDiff = 60 * 60 * 9; // UTCとJSTの時差 +9時間
                let step1Data = [];
                let step2Data = [];
                //------------- step1 時刻の端数切り --------------
                if (pThis.daySpanValue == 1) stepSec = 60 * 30;
                if (pThis.daySpanValue >= 2) stepSec = 60 * 60;
                if (pThis.daySpanValue >= 7) stepSec = 60 * 60 * 6;
                if (pThis.daySpanValue >= 14) stepSec = 60 * 60 * 12;
                if (pThis.daySpanValue >= 30) stepSec = 60 * 60 * 24;
                //-----------------------------------------------
                for (let obj of argData) {
                    let unixTIme = pThis.$moment(obj.x).unix();
                    unixTIme = unixTIme + utcDiff;
                    unixTIme = Math.floor(unixTIme / stepSec) * stepSec;
                    unixTIme = unixTIme - utcDiff;
                    let x = pThis.$moment(unixTIme, "X").format("YYYY-MM-DD HH:mm");
                    step1Data.push({ x: x, y: obj.y });
                }
                //--- step2 端数切りした結果、時刻が同じ場合はまとめる -----
                for (let fst of step1Data) {
                    let searchFlg = false;
                    for (let snd of step2Data) {
                        if (snd.x == fst.x) {
                            searchFlg = true;
                            snd.y = snd.y + fst.y;
                        }
                    }
                    if (searchFlg == false) {
                        step2Data.push({ x: fst.x, y: fst.y });
                    }
                }
                return step2Data;
            }
            //---------------------------------
        },
        //====================================================
        async registSeaChartData() {
            //----------------------
            this.chartDataSetWater = { datasets: [] };
            if (this.dbData.xyWaterOxygen.length <= 2) {
                //「DOセンサー水温値」が無い場合は「塩分センサー水温値」を表示
                this.chartDataSetWater.datasets.push({
                    label: this.chartLabel.water_salinity, //"水温",
                    data: this.dbData.xyWaterSalinity,
                    borderColor: this.chartColor.water_salinity, // "skyblue",
                    borderWidth: 2,
                    fill: false,
                });
            }
            this.chartDataSetWater.datasets.push({
                label: this.chartLabel.water_oxygen, //"水温",
                data: this.dbData.xyWaterOxygen,
                borderColor: this.chartColor.water_oxygen, //"royalblue",
                borderWidth: 2,
                fill: false,
            });
            //----------------------
            this.chartDataSetSalinity = { datasets: [] };
            this.chartDataSetSalinity.datasets.push({
                label: this.chartLabel.salinity, //"塩分",
                data: this.dbData.xySalinity,
                borderColor: this.chartColor.salinity,
                borderWidth: 2,
                fill: false,
            });
            //----------------------
            this.chartDataSetOxygen = { datasets: [] };
            this.chartDataSetOxygen.datasets.push({
                label: this.chartLabel.oxygen, //"DO",
                data: this.dbData.xyOxygen,
                borderColor: this.chartColor.oxygen,
                borderWidth: 2,
                fill: false,
            });
            //----------------------
            this.chartDataSetPH = { datasets: [] };
            this.chartDataSetPH.datasets.push({
                label: this.chartLabel.ph, //"pH",
                data: this.dbData.xyPH,
                borderColor: this.chartColor.ph,
                borderWidth: 2,
                fill: false,
            });
            //----------------------
            this.chartDataSetFishCount = { datasets: [] };
            this.chartDataSetFishCount.datasets.push({
                label: this.chartLabel.fish_count, //"収容尾数",
                data: this.dbData.xyFishCount,
                borderColor: this.chartColor.fish_count,
                borderWidth: 2,
                fill: false,
                steppedLine: true, // 収容尾数はステップライン
                // lineTension: 0, // draw straightline
            });
            //----------------------
            this.chartDataSetFeed = { datasets: [] };
            this.chartDataSetFeed.datasets.push({
                label: this.chartLabel.feed,
                data: this.dbData.xyFeed,
                borderColor: this.chartColor.feed,
                backgroundColor: this.chartColor.feed,
                borderWidth: 2,
                fill: true,
                //barPercentage: 1.2,
                //categoryPercentage: 1.0,
            });
            //----------------------
        },
        //====================================================
        isExistSensor(sensor) {
            // sensor = CT / DO / PH
            if (Object.keys(this.dbDevice).length == 0) return false;
            if (this.dbDevice.sensors.toUpperCase().split(",").includes(sensor.toUpperCase())) return true;
            else false;
        },
        //====================================================
    },
};
</script>
