<template>
    <v-container fluid>
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <v-card class="blue darken-2 my-3">
            <v-app-bar dark color="blue darken-3">
                <v-app-bar-title>
                    <h3>ユーザ情報</h3>
                </v-app-bar-title>
                <v-spacer></v-spacer>
            </v-app-bar>
            <v-card-text class="white">
                <v-row>
                    <v-col cols="12">
                        <div class="ma-4"></div>
                        <v-text-field label="ユーザ名" v-model="dbUser.name" outlined dense readonly hide-details></v-text-field>
                        <div class="text-right text-caption">変更不可</div>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="メール" v-model="dbUser.email" outlined dense hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- --------------------- -->
            <v-card-text class="white">
                <v-row>
                    <v-col class="text-right pa-0">
                        <v-btn color="success" @click="axiosSubmit()" width="100" class="mr-3">更新</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- --------------------- -->
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "User",
    components: {},
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbUser: { id: "", name: "", permit: [] },
            //---------------------------
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------------------
        this.isLoading = true;
        const promiseUser = this.axiosGetUsers();
        await promiseUser;
        await new Promise((r) => setTimeout(r, 500));
        this.isLoading = false;
        window.scrollTo(0, 0);
    },
    //========================================================
    methods: {
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                params: { _fields: "id,name,email" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosSubmit() {
            //-----------------------------------
            let axiosData = {
                email: this.dbUser["email"],
            };
            //-----------------------------------
            const primaryKey = this.dbUser.id;
            let restMethod = "PUT";
            let restUrl = "/web/api/users/" + primaryKey;
            await this.axios({
                method: restMethod,
                url: restUrl,
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosData,
                timeout: 30000,
            })
                .then(() => {
                    this.axiosGetUsers();
                })
                .catch(function (error) {
                    if (error.response.data.err == "ER_DUP_ENTRY") {
                        alert("重複しています。\n保存できません。");
                    } else {
                        alert("ん？ " + error.response.data.err);
                    }
                });
            //-----------------------------------
            await new Promise((r) => setTimeout(r, 100));
        },
        //===================================================
    },
    //========================================================
};
</script>
