<template>
    <v-container>
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <v-text-field outlined dense hide-details="false" v-model="keywordFilter" label="キーワードフィルター" @change="buildDeviceTree()" v-show="!isLoading"></v-text-field>
        <!-- ============================================= -->
        <div v-for="obj in deviceTree" :key="obj.device.id">
            <v-card class="blue darken-2 my-3">
                <v-carousel height="auto" hide-delimiters show-arrows-on-hover :show-arrows="true" :continuous="false">
                    <v-carousel-item>
                        <v-card-text class="white--text pa-1">
                            <h2 class="ma-2 text-center">
                                {{ obj.device.name }}
                            </h2>
                            <!-- ============================================= -->
                            <v-simple-table dense>
                                <thead class="blue lighten-3 text-center">
                                    <tr>
                                        <td width="25%" class="pa-0 font-weight-bold">水温(℃)</td>
                                        <td width="25%" class="pa-0 font-weight-bold">塩分</td>
                                        <td width="25%" class="pa-0 font-weight-bold">DO(mg/L)</td>
                                        <td width="25%" class="pa-0 font-weight-bold">pH</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div v-show="!obj.sensor.oxygen_temp">
                                                <h2 :class="chkLimit(obj.device.id, 'wt', obj.sensor.salinity_temp)">{{ roundNumber(obj.sensor.salinity_temp, "") }}</h2>
                                            </div>
                                            <div v-show="obj.sensor.oxygen_temp">
                                                <h2 :class="chkLimit(obj.device.id, 'wt', obj.sensor.oxygen_temp)">{{ roundNumber(obj.sensor.oxygen_temp, "") }}</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <h2 :class="chkLimit(obj.device.id, 'ct', obj.sensor.salinity)">{{ roundNumber(obj.sensor.salinity, "") }}</h2>
                                        </td>
                                        <td>
                                            <h2 :class="chkLimit(obj.device.id, 'do', obj.sensor.oxygen)">{{ roundNumber(obj.sensor.oxygen, "") }}</h2>
                                        </td>
                                        <td>
                                            <h2 :class="chkLimit(obj.device.id, 'ph', obj.sensor.ph)">{{ roundNumber(obj.sensor.ph, "") }}</h2>
                                        </td>
                                    </tr>
                                </thead>
                            </v-simple-table>
                            <!-- ============================================= -->
                        </v-card-text>
                        <h5 class="text-right white--text mx-2">
                            {{ dateFormat(obj.device.alived_at, "Y年M月D日 HH:mm") }}
                            <v-icon color="yellow" v-show="chkAlive(obj.device.alived_at)">mdi-alert</v-icon>
                        </h5>
                    </v-carousel-item>
                    <v-carousel-item>
                        <v-container>
                            <h2 class="white--text ma-2">{{ obj.device.name }}</h2>
                            <v-row>
                                <v-col class="text-center">
                                    <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: obj.device.id } }" color="success"><v-icon>mdi-chart-line</v-icon>グラフ</v-btn>
                                </v-col>
                                <v-col class="text-center" v-show="dbUser.permit.includes('feed')">
                                    <v-btn class="ma-1" :to="{ name: 'FeededList', params: { device_id: obj.device.id } }" color="success"><v-icon>mdi-beaker-plus</v-icon>給餌量/尾数</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-carousel-item>
                </v-carousel>
            </v-card>
        </div>
    </v-container>
</template>

<script>
// @ is an alias to /src

export default {
    name: "DevicesList",
    components: {},
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbUser: { id: "", name: "", permit: [] },
            dbDevices: [],
            dbLastSensings: [],
            // dbChartConfigs: [],
            deviceTree: [],
            keywordFilter: "",
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------
        this.isLoading = true;
        //-----------------------
        //非同期でAxiosGet
        const promiseDevice = this.axiosGetDevices();
        const promiseSite = this.axiosGetSites();
        const promiseUser = this.axiosGetUsers();
        const promiseSensor = this.axiosGetLastSensings();
        const promiseNotify = this.axiosGetNotifyEmailConfigs();
        //-----------------------
        //上記の終了を待つ
        await promiseDevice;
        await promiseSite;
        await promiseUser;
        await promiseSensor;
        await promiseNotify;
        //-----------------------
        //上記が全て取得終了したタイミングで、配列マージする
        await new Promise((r) => setTimeout(r, 10));
        await this.buildDeviceTree();
        //-----------------------
        this.isLoading = false;
        //-----------------------
        window.scrollTo(0, 0);
    },
    //========================================================
    methods: {
        //====================================================
        async axiosGetSites() {
            // await this.axios({
            //     method: "GET",
            //     url: "/web/api/sites",
            //     //params: { "_order[sort_no]": "desc" },
            //     headers: { Authorization: "Bearer " + this.jwt },
            //     timeout: 30000,
            // }).then((response) => {
            //     this.dbSites = response.data.json;
            // });
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                params: { _fields: "id,name,permit" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                    let permit = this.dbUser["permit"];
                    this.dbUser["permit"] = permit.split(",");
                }
            });
        },
        //====================================================
        async axiosGetDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { "_order[id]": "asc" },
                //params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
                for (let i in this.dbDevices) {
                    let borders = this.dbDevices[i]["sensor_borders"];
                    if (!borders) borders = {};
                    else {
                        borders = JSON.parse(borders);
                    }
                    this.dbDevices[i]["sensor_borders"] = borders;
                }
            });
        },
        //====================================================
        async axiosGetLastSensings() {
            await this.axios({
                method: "GET",
                url: "/web/api/last_sensings",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbLastSensings = response.data.json;
            });
        },
        //====================================================
        async axiosGetNotifyEmailConfigs() {
            await this.axios({
                method: "GET",
                url: "/web/api/notify_email_configs",
                //params: { user_id: this.user_id },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbNotifyMailConfigs = response.data.json;
            });
        },
        //====================================================
        chkLimit(device_id, sensor, val) {
            let ret = "";
            for (let i in this.dbNotifyMailConfigs) {
                let id = this.dbNotifyMailConfigs[i].device_id;
                let fld = this.dbNotifyMailConfigs[i].sensor_fld;
                let up_low = this.dbNotifyMailConfigs[i].upper_lower;
                let limit = this.dbNotifyMailConfigs[i].limit_val;
                // self.console.log({ id, fld, up_low, limit });
                if (device_id == id && sensor == fld) {
                    if (up_low == "upper") {
                        if (val > limit) ret = "red"; //"red lighten-1";
                    }
                    if (up_low == "lower") {
                        if (val < limit) ret = "red"; //"red lighten-3";
                    }
                }
            }
            //self.console.log({ device_id, sensor, val, ret });
            return ret;
        },
        //====================================================
        // async getChartConfigs() {
        //     await this.axios({
        //         method: "GET",
        //         url: "/web/api/chart_configs",
        //         headers: { Authorization: "Bearer " + this.jwt },
        //         timeout: 30000,
        //     }).then((response) => {
        //         this.dbChartConfigs = response.data.json;
        //     });
        // },
        //====================================================
        buildDeviceTree() {
            //----------------------------------------------
            let rating = this.$localStorage.get("rating");
            rating = rating ? JSON.parse(rating) : {};
            //----------------------------------------------
            this.deviceTree = [];
            for (let idx in this.dbDevices) {
                let objDevice = JSON.parse(JSON.stringify(this.dbDevices[idx]));
                let device_id = objDevice.id;
                let site_id = objDevice.site_id;
                let objSite = {};
                let objSensor = {};
                let objConfig = {};
                for (let idx in this.dbSites) {
                    if (site_id == this.dbSites[idx].id) {
                        objSite = this.dbSites[idx];
                        break;
                    }
                }
                for (let idx in this.dbLastSensings) {
                    if (device_id == this.dbLastSensings[idx].device_id) {
                        objSensor = this.dbLastSensings[idx];
                        break;
                    }
                }
                // for (let idx in this.dbChartConfigs) {
                //     if (device_id == this.dbChartConfigs[idx].device_id) {
                //         objConfig = this.dbChartConfigs[idx];
                //         break;
                //     }
                // }
                //------------------------------------
                // キーワードフィルターが「空」or「デバイス名」「組織名」に含まれた場合は表示する。
                let isDisp = false;
                if (!this.keywordFilter) isDisp = true;
                if (String(objDevice.name).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.name).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.pref).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.city).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (isDisp) {
                    objDevice.rating = rating[device_id] ? rating[device_id] : 0;
                    this.deviceTree.push({
                        device: objDevice,
                        site: objSite,
                        sensor: objSensor,
                        config: objConfig,
                    });
                }
            }
            //--------------------------------------------------
            // お気に入り順に、並べ替え
            this.deviceTree.sort(function (a, b) {
                let ret = 0;
                if (a.device.rating > b.device.rating) ret = -1;
                if (a.device.rating < b.device.rating) ret = 1;
                if (a.device.rating == b.device.rating) ret = 0;
                return ret;
            });
            //--------------------------------------------------
        },
        //====================================================
        dateFormat(strDate, format) {
            return this.$moment(strDate).format(format);
        },
        //====================================================
        roundNumber(val, unit) {
            if (val == null) return "---";
            let ret = Math.round(val * 100) / 100;
            ret = ret.toFixed(2) + String(unit);
            return ret;
        },
        //====================================================
        chkAlive(alived_at) {
            if (!alived_at) return false;
            let now = this.$moment().add(-2, "hours");
            let alive = this.$moment(alived_at);
            if (now < alive) return false;
            return true;
        },
        //====================================================
    },
};
</script>
